@import "~antd/dist/antd.css";

/*.ant-table-row-selected {*/
/*    color: green !important;*/
/*    text-decoration: line-through;*/

/*}*/

mark {
  background-color: #00af50 !important;
  color: white !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.App {
  text-align: center;
}

.shadow {
  boxshadow: " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2)" !important;
}

.input-shadow {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 10px 0 rgba(0, 0, 0, 0.15) !important;
}

.darkmode--activated img {
  isolation: isolate;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #7000a6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-tabs-tab-active {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 10px 0 rgba(0, 0, 0, 0.15) !important;
}

.darkmode-layer,
.darkmode-toggle {
  z-index: 99999;
}

button {
  darkmode-ignore: true !important;
}

img {
  isolation: isolate !important;
}

.text-Shadow {
  text-shadow: 2px 2px #ff0000 !important;
}

.ant-menu-item-selected,
.ant-menu-submenu-selected {
  background-color: #d8f0df;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 10px 0 rgba(0, 0, 0, 0.15) !important;
}

/* html {
  zoom: 90%;
} */

@media print {
  .noprint {
    visibility: hidden;
  }
}

@media screen {
  .onlyprint {
    display: none;
  }
}
