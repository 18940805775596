@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Override default styles for the survey */

/* Change the background color */
.sd-root-modern {
  background-color: inherit !important;
}
.sd-tagbox-item_clean-button {
  background-color: #00af50 !important;
  border-color: #00af50 !important;
  border-radius: 30px !important;
  font-size: 10px !important;
}

.sd-btn--action {
  background-color: #00af50 !important;
  border-color: #00af50 !important;
  border-radius: 30px !important;
}
.shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}
